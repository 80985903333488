import { makeAutoObservable } from "mobx";
import { Alarm } from "../models/Alarm";
import agent from "../services/Api/agent";
import { IAlarmHistoryForm } from "../models/Pharse2/Alarm";
import { AlarmDto } from "../models/Alarm";

export default class AlarmStore{
	AlarmHistories: AlarmDto[] | undefined = undefined
	loadingInitial: boolean = false
    constructor(){
        makeAutoObservable(this);
    }

    getAlarmHistories = async (record:IAlarmHistoryForm, setOriginal_Datas:(datas:AlarmDto[])=>void ) => {
		this.loadingInitial = true
		console.log(record)
		debugger
        let result = await agent.Alarms.list(record.projectId,record.locationId,record.sourceTypeId,record.fromDate,record.toDate);
		setOriginal_Datas(result);
		this.AlarmHistories = result;
		this.loadingInitial = false
    }

}