// Navbar.tsx
import React, { useState } from 'react';
import NavItem from './NavItem';
import { Link } from 'react-router-dom';
import { useStore } from '../../stores/store';
import AdminNav from './AdminNav/AdminNav';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import Project70AdminNav from './AdminNav/Project70AdminNav';

export interface NavItem {
  title: string;
  src: string;  // If you're using image icons, change this to string
  path: string
}




export default observer(function Navbar() {
  const navItems: NavItem[] = [
    { title: 'Dashboard', src: 'overview', path: '/dashboard' },
    { title: 'Graph', src: 'graph', path: '/graph' },
    { title: 'Alarm', src: 'alarm', path: '/alarm' },
    { title: 'Data', src: 'download', path: '/data' },
    //{ title: 'Performance', src: 'download', path: '/performance' },
  ];

  const { userStore, commonStore } = useStore();
  const { user, CheckIfHkiaEmail } = userStore;
  const location = useLocation();
  const { pathname } = location;
  // if(user?.email.split("@")[1] ==="hkairport.com" || user?.role === "Castco_Admin") {
  //   navItems.push({ title: 'Dashboard', src: 'overview', path: '/dashboard' }) 
  // } 


  return (
    <nav className="z-50 text-base bg-nav-blue  items-center font-inter flex flex-col h-full w-[75px] md:min-w-[227px] gap-4 fixed ">

      <Link to={"/dashboard"}>
        <div className="w-[80px] h-[45px] md:w-190 md:h-106  mdml-7 mt-15 hidden md:flex justify-center mb-5" onClick={() => { commonStore.checkAndCloseNavBar() }}>
          {CheckIfHkiaEmail()
            ? <img src="/assets/nav/hkia.png" style={{ height: '100%' }} />
            : <img src="/assets/nav/maple.png" style={{ height: '100%' }} />
          }
        </div>
      </Link>



{navItems.map((item, index) => {
  if (item.path === "/alarm" && CheckIfHkiaEmail()) return null;
  return (
    <NavItem key={index} title={item.title} imgSrc={item.src}
      path={item.path} isSelected={pathname === item.path} />
  );
})}

      {(user?.role === 'HKL_Admin' || user?.role === 'Castco_Admin') &&
        <AdminNav />
      }
      {(user?.role === 'Project70Admin') &&
        <Project70AdminNav />
      }

    </nav>)
})








/* <Menu.Item >
                  <Button positive content='Logout' onClick={logout}  />
              </Menu.Item> */