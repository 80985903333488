import { LocationPairDateTimeScriptTable } from "../../components/FormElement/MultiLevelSelectElement/MultiLevelSelectElement";

interface StringFunctionDict {
	[key: string]: (arg: number) => any;
  }
  function parseCsharpustomDateTime(dateTimeStr:string) {
    const [date, time, period] = dateTimeStr.split(' ');
    const [day, month, year] = date.split('/').map(Number);
    let [hours, minutes, seconds] = time.split(':').map(Number);

    // Adjust for AM/PM
    if (period === "PM" && hours !== 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    return new Date(year, month - 1, day, hours, minutes, seconds);
}
function parseCustomDateTime(dateString:string) {
	const [datePart, timePart] = dateString.split(' ');
	const [year, month, day] = datePart.split('-').map(Number);
	const [hours, minutes, seconds] = timePart.split(':').map(Number);
  
	// Adjust year to full format
	const fullYear = year < 100 ? 2000 + year : year;
  
	// Create and return the Date object
	return new Date(fullYear, month - 1, day, hours, minutes, seconds);
  }
export default function postProcessingScript (locationIds: number[], locationData: number[][], locationDateTime: string[][], locationScriptTable?: LocationPairDateTimeScriptTable):number[][] {
		console.log(`locationScriptTable`,locationScriptTable)
		console.log(`locationDateTime`,locationDateTime)
		var locationIndex = 0
		var newData: number[][] = []

		if (!locationScriptTable) {
			return locationData
		}
		console.log(locationScriptTable)
		for (var locId of locationIds) {
			if (locationScriptTable[locId]) {
				const compiledDateTimeScriptTable: StringFunctionDict = {};
				for (let dt in locationScriptTable[locId]) {
					let body = locationScriptTable[locId][dt].substring(locationScriptTable[locId][dt].indexOf("{") + 1, locationScriptTable[locId][dt].lastIndexOf("}"));
					let params = locationScriptTable[locId][dt].substring(locationScriptTable[locId][dt].indexOf("(") + 1, locationScriptTable[locId][dt].indexOf(")"));
					let convert = new Function(params, body) as (arg: number) => number;
					compiledDateTimeScriptTable[dt] = convert;
					console.log(convert, locationScriptTable[locId])
				}

				var arr = []
				let index = 0
				for (const dataPoint of locationData[locationIndex]) {
					let suitableDateTime:string|undefined
					let suitableDateTimeParsed:Date|undefined//just for performance

					for (let dt in compiledDateTimeScriptTable){
						let tableDateTime = new Date(dt);
						let dataPointDateTime = parseCustomDateTime(locationDateTime[locationIndex][index]);
						if (dataPointDateTime>tableDateTime && (suitableDateTimeParsed===undefined?true:tableDateTime>suitableDateTimeParsed!))
							{
								suitableDateTime= dt;
								suitableDateTimeParsed=new Date(dt);
							}
					}
					arr.push(parseFloat(compiledDateTimeScriptTable[suitableDateTime!](dataPoint)));
					index++;
				}
				newData[locationIndex] = arr
			}
			else {
				newData[locationIndex] = locationData[locationIndex]
			}
			locationIndex++;
		}
		return newData;
	}