import { useEffect, useState } from "react";
import { Dict, GraphData, GraphError, GraphForm, GraphFormVal, ResourceData, SelectListOption } from "../../models/Pharse2/Graph";
import Plotly, { ScatterData } from 'plotly.js';
import { SelectElement } from "../../components/FormElement/SelectElement";
import { CustomCalendar } from "../../components/Input/CustomCalendar";
import GeneralButton from "../../components/Button/GeneralButton";
import { useStore } from "../../stores/store";
import { InputElement } from "../../components/FormElement/InputElement";
import CheckboxInfo from "../../components/form/Project/NewAlarmControlTable/InfoTable/Checkbox/CheckboxInfo";
import { Project } from './../../models/Project';
import { DataOptions } from "../../models/Pharse2/Data";
import { observer } from "mobx-react-lite";
import { callback } from "chart.js/dist/helpers/helpers.core";
import { flushSync } from 'react-dom';
import CheckBoxInfoElement from "../../components/FormElement/CheckBoxInfoElement/CheckBoxInfoElement";
import MultiLevelSelectElement, { OptionContainer, LocationPairDateTimeScriptTable } from "../../components/FormElement/MultiLevelSelectElement/MultiLevelSelectElement";
import { title } from 'process';
import moment from "moment";
import UserStore from "../../stores/UserStore";

interface props {
	record?: GraphData;
	setRecord: (x: GraphData) => void
	isMap: boolean

	localForm: GraphForm
	setlocalForm: React.Dispatch<React.SetStateAction<GraphForm>>

}



export default observer(function GraphForm(p: props) {
	const [currentScript, setCurrentScript] = useState<LocationPairDateTimeScriptTable>()
	const [currentUnit, setCurrentUnit] = useState<string>()


	const [dateErrorMsg, setDateErrorMsg] = useState<string | undefined>();

	const [dataOptions, setDataOptions] = useState(new DataOptions())

	const [graphData, setGraphData] = useState<GraphData[]>([])

	const [locationTableOpen, setLocationTable] = useState(false);
	const locationOptionIds: number[] = (dataOptions.locationList ? dataOptions.locationList.map(x => x.id) : []);
	const [errors, setErrors] = useState<GraphError>(new GraphError());

	const { graphStore, summaryStore, userStore } = useStore();
	const { LoadingSummarys, LoadingIntervals, summaryTypes, intervalTypes, areArraysEqual } = summaryStore;
	const [submitButtonDisable, setSubmitDisable] = useState(true)
	const {CheckIfCastcoAdmin} = userStore
	const GraphFormValidation = (form: GraphForm) => {
		const error: GraphError = new GraphError()
		setDateErrorMsg(undefined)
		if ((form.fromDate !== undefined && form.fromDate !== '') && (form.toDate !== undefined && form.toDate !== '')) {
			if (new Date(form.fromDate) > new Date(form.toDate)) {
				error.fromDate = true
				error.toDate = true
			}
		}

		if (form.locationIds.length >= 2) {
			if (form.fromDate === undefined || form.fromDate === '') {
				error.fromDate = true;

			}
			if (form.toDate === undefined || form.fromDate === '') {
				error.toDate = true;
				setDateErrorMsg("Date is required when selected locations are more than 2")

			}
		}
		if (form.projectId === undefined || form.projectId === -1) {
			error.projectId = true
		}
		if (form.locationIds.length === 0) {
			error.locationIds = true
		}
		if (form.sourceTypeId === undefined || form.sourceTypeId === -1) {
			error.sourceTypeId = true
		}
		if (form.intervalId === undefined || form.intervalId === -1) {
			error.intervalId = true
		}
		if (form.summaryId === undefined || form.summaryId === -1) {
			error.summaryId = true
		}

		return error;


	}


	const getSourceTypePref = () => {
		if (localStorage.getItem('sourcePref') == undefined)
			return undefined
		return JSON.parse(localStorage.getItem('sourcePref')!)[p.localForm.projectId] // return undefined or Id
	}
	const setSourceTypePref = (srcId: number) => {
		let newPref: any = {}
		let oldPref = localStorage.getItem("sourcePref")
		if (oldPref != undefined) {
			newPref = JSON.parse(oldPref)
		}
		newPref[p.localForm.projectId] = srcId
		console.log(`newPref`)
		console.log(newPref)
		newPref = JSON.stringify(newPref)
		localStorage.setItem('sourcePref', newPref)
	}

	useEffect(() => {
		LoadingSummarys().then(() => {
			LoadingIntervals().then(() => {
				p.setlocalForm({ ...p.localForm, summaryId: summaryStore.summaryTypes[1].id, intervalId: summaryStore.intervalTypes[0].id })
			})
		})//set default to Peek


		graphStore.getprojectList().then(projs => { // handling the rerender bug here
			if (p.isMap && projs && p.localForm.projectId && p.localForm.locationIds.length > 0)
				getlocationList(p.localForm.projectId).then(locas => {
					console.log("locas")
					console.log(locas)
					graphStore.getsourceTypeList(p.localForm.locationIds).then(sources => {
						console.log("local form ", p.localForm.locationIds);
						if (sources && sources.length > 0) {
							let finalSrcId = getSourceTypePref() ?? sources[0].id
							setDataOptions({ ...dataOptions, locationList: locas ? locas : [], projectList: projs, sourceTypeList: sources })
							p.setlocalForm({ ...p.localForm, sourceTypeId: finalSrcId, isScripted: graphStore.handleOptionIsScriptedIdx(sources, finalSrcId) })
							handleSubmit(undefined, { ...p.localForm, sourceTypeId: finalSrcId }, graphStore.handleGraphMapScript(sources, finalSrcId))

						}
						else if (locas && projs) {
							setDataOptions({ ...dataOptions, locationList: locas, projectList: projs })
						}

					})
				})
			else if (projs)
				setDataOptions({ ...dataOptions, projectList: projs })
		})


	}, [])

	useEffect(() => {
		if (p.localForm.projectId > 0 && !p.isMap) {
			p.setlocalForm({ ...p.localForm, locationIds: [], sourceTypeId: -1 })
		}
	}, [p.localForm.projectId, p.isMap])
	useEffect(() => {
		if (p.localForm.locationIds.length < 2) {
			if (p.localForm.sourceTypeId !== undefined && p.localForm.sourceTypeId !== -1) {
				setSubmitDisable(false)
			}
		}
		else if(p.localForm.locationIds.length >= 2){
			setDateErrorMsg("Date is required when selected locations are more than 2")
			if(p.localForm.toDate !== undefined && p.localForm.toDate !== '' && p.localForm.toDate !== null && p.localForm.fromDate !== undefined && p.localForm.fromDate !== '' && p.localForm.fromDate !== null && currentUnit !== undefined){
				setDateErrorMsg(undefined)
				setSubmitDisable(false)
			}
		}
		else setSubmitDisable(true)
	}, [p.localForm, currentScript, currentUnit])

	const { getprojectList, getlocationList, getsourceTypeList, getgraphData } = graphStore;




	const handleProjectIdSelected = (newId: number | string | boolean) => {
		// after select the project item. fetch
		if (typeof newId === 'number') {
			getlocationList(newId).then(i => { if (i) setDataOptions({ ...dataOptions, locationList: i }) })
			setSubmitDisable(true)
		}

	}



	const handleLocationsSelected = async (newIds: number[]) => {
		if (newIds != p.localForm.locationIds) {
			setDataOptions({ ...dataOptions, sourceTypeList: [] })
			p.setlocalForm({ ...p.localForm, locationIds: newIds, sourceTypeId: -1 })
			await graphStore.getsourceTypeList(newIds).then(i => {
				if (i)
					setDataOptions({ ...dataOptions, sourceTypeList: i })
				setSubmitDisable(true)
				errors.sourceTypeId = true
			})
		}
	}
	//replaceForm => mapGraph, localForm => graphPage
    const handleSubmit = (e?: React.FormEvent<HTMLFormElement>, replaceForm?: GraphForm, replaceObj?: any) => {
		if (e) e.preventDefault();
        var newError = GraphFormValidation(replaceForm ?? p.localForm)
		setErrors(newError)
		if (Object.values(newError).some(x => x === true)) {
			setSubmitDisable(true)
			return;
		}
        setSourceTypePref(replaceForm ? replaceForm.sourceTypeId : p.localForm.sourceTypeId)
        const result = getgraphData(replaceForm ?? p.localForm).then(data => {
			//set original data, handle script, set record
			if (data) {
				console.log(`p.localForm`,p.localForm)
				if(((replaceForm ?? p.localForm).sourceTypeId === 55 || (replaceForm ?? p.localForm).sourceTypeId === 16)&&!CheckIfCastcoAdmin()){
					for (let array of data.yData) {
						for (let j = 0; j < array.length; j++) {
							if (array[j] < 0) array[j] = 0;
						}
					}
				}
				let currentSource = dataOptions.sourceTypeList.find((s) => { return s.id === p.localForm.sourceTypeId })
				console.log(`currentSource`,currentSource)
				console.log(`i.alarmLevels`,data.alarmLevels)
                p.setRecord({ ...data, yData: graphStore.GraphPostProcessingScript(data.locationIds, data.yData, data.xData, replaceObj != undefined ? replaceObj.script : currentScript), yAxis: data.yAxis, yUnit: (replaceObj != undefined ? replaceObj.unit : currentUnit != undefined ? currentUnit : ''), alarmUnit: currentSource?.subOptions.length === 2 ? currentSource.subOptions[1].unit : currentSource?.subOptions[0].unit,alarmLevels:(currentSource?.subOptions.length === 2 && p.localForm.isScripted === false)?[]:data.alarmLevels})
			}
		})
	}
	useEffect(() => {
		if (p.record !== undefined && p.record.alarmUnit === undefined) {
			let currentSource = dataOptions.sourceTypeList.find((s) => { return s.id === p.localForm.sourceTypeId })
			p.setRecord({ ...p.record, alarmUnit: currentSource?.subOptions.length === 2 ? currentSource.subOptions[1].unit : currentSource?.subOptions[0].unit })
		}
		if (p.record !== undefined && (p.record.yUnit === undefined || p.record.yUnit === '') && p.isMap === true) {
			let currentSource = dataOptions.sourceTypeList.find((s) => { return s.id === p.localForm.sourceTypeId })
			p.setRecord({ ...p.record, yUnit: currentSource?.subOptions[0].unit })
		}

	}, [dataOptions.sourceTypeList, p.record])
	const generateLocationList = () => {
		var result: string = "";

		for (let i = 0; i < p.localForm.locationIds.length; i++) {//get location name from locationIds
			var name = dataOptions.locationList.find(x => x.id == p.localForm.locationIds[i])?.name
			if (name != undefined)
				result += name
			if (i < p.localForm.locationIds.length - 1)
				result += ', '
		}
		console.log(`generateLocationList`)
		console.log(dataOptions.locationList)
		return result;

	}

	const showRecord = () => {
		console.log(p.localForm)
	}

	useEffect(() => {
		graphDataSorting()
		console.log(p.record)
	}, [p.record])

	const handleMultiOnChange = (unit: string, parentValue: number, subValue: boolean, script: any) => {
		//whether user choosed processed or raw is determined here by subValue
		p.setlocalForm({ ...p.localForm, sourceTypeId: parentValue, isScripted: subValue })
		setCurrentScript(script)
		setCurrentUnit(unit)
	}


	const graphDataSorting = () => {
		var combinedXData: string[] = []
		var newYData: number[][] = [[], []]
		combinedXData = []
		p.record?.xData.forEach((xDataset, locationIndex) => {
			xDataset.forEach((xData, xDataIndex) => {
				if (!(combinedXData.includes(xData))) {
					combinedXData.push(xData)
				}
			})
		})
		combinedXData = combinedXData.sort((a, b) => moment(a, 'DD/MM/YYYY h:mm:ss a').valueOf() - moment(b, 'DD/MM/YYYY h:mm:ss a').valueOf())
		p.record?.locationIds.forEach((location, locationIndex) => {
			if (newYData[locationIndex] === undefined) { newYData[locationIndex] = [] }
			combinedXData.forEach((newX, newXIndex) => {
				newYData[locationIndex][newXIndex] = NaN
			})
			p.record!.yData[locationIndex].forEach((yData, yIndex) => {
				var newYindex = combinedXData.findIndex(x => x === p.record!.xData[locationIndex][yIndex])
				newYData[locationIndex][newYindex] = yData
			})
			p.record!.xData[locationIndex] = combinedXData
			p.record!.yData[locationIndex] = newYData[locationIndex]
		})
	}

	return (
		<div className="border-b-[4px] border-gray-400" onClick={showRecord}>

			<form className="w-full flex flex-col gap-3   h-full mt-2" onSubmit={handleSubmit} >
				<CheckboxInfo isinfotable={true} setMultiSelection={handleLocationsSelected} multiSelection={p.localForm.locationIds} options={dataOptions.locationList} title={""} isOpen={locationTableOpen} setisOpen={setLocationTable} />
				<div className="w-full flex flex-row gap-3 items-center " onClick={showRecord}>
					<div className="grow" style={{width:"auto"}}>
						<CustomCalendar name={"fromDate"} setRecord={p.setlocalForm} record={p.localForm} title="From" isRequired={errors.fromDate} />

					</div>
					<div className="grow" style={{width:"auto"}}>
						<CustomCalendar error2={dateErrorMsg} name={"toDate"} setRecord={p.setlocalForm} record={p.localForm} title="To" isRequired={errors.toDate} />

					</div>
				</div>

				<div className=" flex flex-col md:flex-row gap-3 justify-between flex-wrap">
					<div style={{ width: 'auto' }} className=" grow">
						<SelectElement onlyRead={p.isMap} defValue={p.localForm.projectId} isWithApi={true} isRequired={errors.projectId} options={dataOptions.projectList} title={"Project"} name={"projectId"} setRecord={p.setlocalForm} record={p.localForm} onChange={handleProjectIdSelected} />
						{/* <SelectElement isWithApi={true} isRequired={false} options={locationList} title={"Location"} name={"locationId"} setRecord={p.setp.localForm} record={p.localForm} /> */}
					</div>

					<div style={{ width: 'auto' }} className=" grow">
						{/* <p className="mb-3" onClick={() => console.log(dataOptions)}>Location</p>
                        <div className={` font-bold border-2 border-gray-400 rounded-xl w-[full] p-[5px] h-[37px] ${p.localForm.projectId > 0 && !p.isMap ? "" : "bg-[#D9D9D94D]"}`} style={{ paddingTop: "6px", marginTop: "-5px" }} onClick={() => { if (p.localForm.projectId > 0 && !p.isMap) setLocationTable(true) }} >{generateLocationList()}</div> */}
						<CheckBoxInfoElement content={generateLocationList} title={"Location"} localForm={p.localForm} dataOptions={dataOptions} onClick={() => { if (p.localForm.projectId > 0 && !p.isMap) setLocationTable(true) }} isRequired={errors.locationIds} onlyRead={p.localForm.projectId <= 0 || p.isMap} />
					</div>


					<div style={{ width: 'auto' }} className=" grow">
						{/* <SelectElement defValue={p.localForm.sourceTypeId} isWithApi={true} isRequired={errors.sourceTypeId} options={dataOptions.sourceTypeList} title={"Source Type"} name={"sourceTypeId"} setRecord={p.setlocalForm} record={p.localForm} onlyRead={p.localForm.locationIds.length < 1} /> */}
						<MultiLevelSelectElement defIsScripted={p.localForm.isScripted} onChange={handleMultiOnChange} readOnly={p.localForm.locationIds.length < 1} defValue={p.localForm.sourceTypeId} title={"Source Type"} options={dataOptions.sourceTypeList} isRequired={errors.sourceTypeId} />
					</div>
				</div>

				<div className="flex flex-col md:flex-row gap-3 items-center justify-between pb-2">
					<div className="grow w-full md:w-auto">
						<SelectElement defValue={p.localForm.intervalId} isWithApi={true} isRequired={errors.intervalId} options={intervalTypes} title={"Interval"} name={"intervalId"} setRecord={p.setlocalForm} record={p.localForm} />

					</div>
					<div className="grow w-full md:w-auto">
						<SelectElement defValue={p.localForm.summaryId} isWithApi={true} isRequired={errors.summaryId} options={summaryTypes} title={"Summary"} name={"summaryId"} setRecord={p.setlocalForm} record={p.localForm} onlyRead={p.isMap} />

					</div>
					<div className='grow-0 w-full md:w-auto' >
						<GeneralButton title={"Search"} disabled={submitButtonDisable} type={'submit'} />

					</div>
				</div>

			</form>


		</div>
	)
})
