import { makeAutoObservable } from "mobx";
import { Dict, GraphForm, SelectListOption } from "../models/Pharse2/Graph";
import agent from "../services/Api/agent";
import { LocationPairDateTimeScriptTable, OptionContainer } from "../components/FormElement/MultiLevelSelectElement/MultiLevelSelectElement";
import postProcessingScript from "../services/Data/PostProcessingScript";
interface StringFunctionDict {
	[key: string]: (arg: number) => any;
  }
  function parseCsharpustomDateTime(dateTimeStr:string) {
    const [date, time, period] = dateTimeStr.split(' ');
    const [day, month, year] = date.split('/').map(Number);
    let [hours, minutes, seconds] = time.split(':').map(Number);

    // Adjust for AM/PM
    if (period === "PM" && hours !== 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    return new Date(year, month - 1, day, hours, minutes, seconds);
}
function parseCustomDateTime(dateString:string) {
	const [datePart, timePart] = dateString.split(' ');
	const [year, month, day] = datePart.split('-').map(Number);
	const [hours, minutes, seconds] = timePart.split(':').map(Number);
  
	// Adjust year to full format
	const fullYear = year < 100 ? 2000 + year : year;
  
	// Create and return the Date object
	return new Date(fullYear, month - 1, day, hours, minutes, seconds);
  }
  
  
export default class GraphStore {

    projectList: SelectListOption[] | undefined = undefined;
    loadingInitial: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    isGraphBadResult = (yData: number[][]) => {
        if (yData.length === 0) {
            return true
        }

        let isAllLength0 = true //check for each array, is it empty
        for (var array of yData) {
            if (array.length !== 0) {
                isAllLength0 = false
                break;
            }

        }
        if (isAllLength0) return true


        for (var array of yData) { // check all data inside each array
            for (var data of array) {
                if (data != null) {
                    return false
                }
            }
        }
        return true
    }
    getprojectList = async () => {
        this.loadingInitial = true

        try {

            const projects = await agent.Graph.projectList();
            this.loadingInitial = false
            return projects

        } catch (error) {
            this.loadingInitial = false
            throw error
        }
    }

    getlocationList = async (projectId: number) => {

        try {
            const locations = await agent.Graph.locationList(projectId);

            return locations;

        } catch (error) {

            throw error

        }
    }

    getsourceTypeList = async (locationId: number[]) => {
        var sourceTypes
        try {
            if (locationId.length === 1) {
                sourceTypes = await agent.Graph.sourceTypeList(locationId[0]);
            }
            else if (locationId.length > 1) {
                sourceTypes = await agent.Graph.sourceTypeList_InCommon(locationId);
            }
            return sourceTypes;
        } catch (error) {
            throw error
        }
    }


    getgraphData = async (graphForm: GraphForm) => {
        this.loadingInitial = true

        try {
            const graphDatas = await agent.Graph.getData2(graphForm);
            this.loadingInitial = false
            return graphDatas;
        } catch (error) {
            this.loadingInitial = false

            throw error
        }
    }

    GraphPostProcessingScript = (locationIds: number[], locationData: number[][], locationDateTime: string[][], locationScriptTable?: LocationPairDateTimeScriptTable):number[][] => {
        if (this.isGraphBadResult(locationData)) {
			return locationData
        }
		return postProcessingScript(locationIds, locationData, locationDateTime, locationScriptTable)
		
    }

    handleGraphMapScript = (options: OptionContainer[], srcId: number) => {
        var option = options.find(x => x.id == srcId);
        if (!option) return undefined
        for (var subOption of option.subOptions) {
            if (subOption.value != null) {
                console.log({script:subOption.value, unit:subOption.unit})
                return {script:subOption.value, unit:subOption.unit}
            }
        }
        return undefined
    }

    handleOptionIsScriptedIdx = (options: OptionContainer[], srcId: number) => { // pick Scripted if can
        var option = options.find(x => x.id == srcId);
        if (!option) return undefined
        let idx = 0
        for (var subOption of option.subOptions) {
            if (subOption.value != null) {
                return Boolean(idx)
            }
            idx++
        }
        return false
    }

    calMax = (numbers: number[]) => {
        let maxYData = -Infinity;
            for (let num of numbers) {
                if (num > maxYData) maxYData = num;
                
            }
            return maxYData
    }
}