import React, { Fragment, useEffect, useState } from 'react';

import NavBar from './components/Nav/NavBar';
import { Button, Container, Segment } from 'semantic-ui-react';


import { observer } from 'mobx-react-lite';
import { Outlet, useLocation } from 'react-router-dom';
import LoginForm from './Features/userForm/LoginForm';
import CommonStore from './stores/CommonStore';
import UserStore from './stores/UserStore';
import { useStore } from './stores/store';
import LoadingComponent from './components/LoadingComponent';
import AdminManagement from './components/AdminManagement';
import Footer from './components/footer';
import Login from './pages/login/login';



function App() {

  const location = useLocation();
  const normalizedPath = location.pathname.toLowerCase()
  const pathsWithoutNavBar = [
    /^\/loginpage$/,
    ///^\/not-found$/,

    /^\/loginpage\/register$/,
    /^\/loginpage\/forget-password$/,
    /^\/loginpage\/reset-password$/
  ];
  const shouldIncludeNavBar = (pathname: string) => {
    return !pathsWithoutNavBar.some(pattern => pattern.test(pathname));
  };
  const includeNavBar = shouldIncludeNavBar(normalizedPath);
  const { userStore, commonStore } = useStore();
  const { token, appLoaded, setAppLoaded, navBarOpened } = commonStore
  const { getUser } = userStore


  useEffect(() => {
    if (localStorage.getItem(`jwt`)) {
      getUser().finally(() => setAppLoaded())
    }
    else {
      setAppLoaded()
    }
  }, [token, setAppLoaded, getUser])

  if (!appLoaded) {
    return <LoadingComponent  />
  }

  return (

    <div className='flex flex-col justify-center items-center min-h-screen overflow-auto w-full'>
      <div className="flex flex-col flex-grow w-full">

        {location.pathname === '/' &&
          <div className="">
            {<Login />}
          </div>
        }

        {location.pathname !== '/' && !includeNavBar && (<div><Outlet /></div>)}


        {location.pathname !== '/' && includeNavBar && (

          <div className="flex flex-col flex-grow">

            {navBarOpened && <NavBar />}
            <div className={` ${!navBarOpened ? 'pl-0' : 'pl-[75px] md:pl-227'} flex-grow  h-full flex flex-col`} id="interface" >
              <AdminManagement /> 

              <div className='flex w-full flex-grow mt-[60px]' style={{height:"calc(100vh-60px)"}}>
                <Outlet />
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}


export default observer(App);