import { makeAutoObservable } from "mobx";
import agent from "../services/Api/agent";
import { DataRow, DataTableDto, IDataForm } from "../models/Pharse2/Data";
import DataForm from "../pages/Data/DataForm";
import { Dict } from "../models/Pharse2/Graph";
import { useStore } from './store';
import { LocationPairDateTimeScriptTable } from "../components/FormElement/MultiLevelSelectElement/MultiLevelSelectElement";
import postProcessingScript from "../services/Data/PostProcessingScript";

export default class DataStore {
    Data: DataTableDto | undefined = undefined
    loadingInitial: boolean = false
    constructor() {
        makeAutoObservable(this)
    }
    getsourceTypeList = async (locationIds: number) => {
        try {
            const sourceTypes = await agent.Graph.sourceTypeList(locationIds)
            return sourceTypes;
        } catch (error) {
            console.log(error)
        }
    }
    clientSetData = async (data: DataTableDto) => {
        this.Data = data;
    }
    getData = async (dataForm: IDataForm, setOriginal_Datas:(datas:DataRow[])=>void, CheckIfCastcoAdmin:()=>boolean, dict?:LocationPairDateTimeScriptTable) => {
        try {
            this.loadingInitial = true
            const data = await agent.Data.getData(dataForm)
			if (dataForm.sourceTypeId === 16 && !CheckIfCastcoAdmin()) {//fulfill Hason proposed making negative water level raw value 0
				for (let i in data.datas) {
					if (data.datas[i].value === undefined) continue;
					if (data.datas[i].value! < 0) data.datas[i].value = 0;
				}
			}
			console.log(`data.datas`)
			console.log(data.datas)
            setOriginal_Datas(data.datas)
			let numbers = data.datas.map((d)=>d.value)
			let processedNumbers = postProcessingScript([dataForm.locationId],[numbers],[data.datas.map((d)=>d.dateTime)],dict)
			for (let index in data.datas){
				data.datas[index].value = processedNumbers[0][index]
			}
            this.Data = data

            this.loadingInitial = false
            return data;

        } catch (error) {
            this.loadingInitial = false

            console.log(error)
        }
    }
    exportAllLocations = async (form: IDataForm) => {
        try {
            this.loadingInitial = true
            const result = await agent.Data.exportAll(form);
            console.log(result.data instanceof Blob);
            console.log(result.data);
            console.log(result)

            // if(result != null){
            //     const contentDisposition = result.headers?.['content-disposition'] || '';
            //     const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
            //     const filename = (matches && matches[1] && matches[1].replace(/['"]/g, '')) || 'untitled';
            // console.log(filename)
            // }




            const blobUrl = URL.createObjectURL(result.data);
            const link = document.createElement('a');

            link.href = blobUrl;
            link.setAttribute('download', 'ProjectId_' + form.projectId + '.zip'); // Updated the file name to 'excel.zip'
            document.body.appendChild(link);
            link.click();

            // Clean up by revoking the Blob URL after usage
            link.addEventListener('click', () => {
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            }, { once: true });

            this.loadingInitial = false;
            return result.data;
        }
        catch (error) {
            this.loadingInitial = false
            console.log(error)
        }
    }


    exportCurrentLocation = async (form: IDataForm) => {
        try {
            this.loadingInitial = true;

            const blob = await agent.Data.exportCurrent(form);
            console.log(blob instanceof Blob);
            console.log(blob);
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = blobUrl;
            link.setAttribute('download', 'file.xlsx'); // Replace 'file.xlsx' with your desired file name
            document.body.appendChild(link);
            link.click();

            // Clean up by revoking the Blob URL after usage
            link.addEventListener('click', () => {
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            }, { once: true });

            this.loadingInitial = false;
            return blob;
        }
        catch (error) {
            this.loadingInitial = false;
            console.log(error);
        }
    }

    postProcessingScript = (locId: number, datas: DataRow[], dict?: Dict) => {

        if (datas.length === 0 || !dict) {
            return datas
        }
        console.log(dict)

        if (dict[locId]) {
            let body = dict[locId].substring(dict[locId].indexOf("{") + 1, dict[locId].lastIndexOf("}"));
            let params = dict[locId].substring(dict[locId].indexOf("(") + 1, dict[locId].indexOf(")"));
			console.log(`body`,body)
			console.log(`params`,params)
            let convert = new Function(params, body);
            console.log(convert, dict[locId])
            for (var d of datas) {
                if (d.value!==undefined||d.value!==null) {
                    d.value = convert(d.value)
                }
            }
        }

        return datas

    }

}