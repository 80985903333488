import React, { useState } from 'react';
import AlarmTable from './AlarmTable';
import DataForm from '../Data/DataForm';
import { DataFormVal, IDataForm } from '../../models/Pharse2/Data';
import AlarmForm from './AlarmForm';
import { AlarmHistoryFormValue, IAlarmHistoryForm } from '../../models/Pharse2/Alarm';

export default function AlarmPage() {
    const [record, setRecord] = useState<IAlarmHistoryForm>(new AlarmHistoryFormValue());

    //const [alarmData, setAlarmData] = useState<>();
    return (
        <div className="pageParentDiv">
			<AlarmForm record={record} setRecord={setRecord} />
            <AlarmTable />
        </div>
    )
}
