import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, TableSortLabel } from "@mui/material";
import { FormEvent, useEffect, useState, useRef } from "react";

import { createTheme } from '@mui/material/styles';
import { BpCheckbox } from "../BpCheckBox";
import { CustomTablePagination } from "./CustomTablePagination";
import * as XLSX from "xlsx";
import { ExportToExcelButton } from "../Button/ExportToExcelButton";
import { Link, useNavigate } from "react-router-dom";
import { router } from "../../router/Routes";
import { RectangleButton } from "../Button/RectangleButton";
import { LoggerSetFormValue } from "../../models/LoggerSet";


export interface CoustomTableProps<T> {
    page: number
    setPage: (arr: number) => void
    apiPage?: number;
    setApiPage?: (arr: number) => void;
    apiCount?: number;
	remark?:React.ReactNode;
    tableBlur?: boolean;
    fixedWidth?: string
    record?: T;
    rowsPerPage?: number;
    maxHeight?: number;
    onSelectedIdsChange?: (arr: string[]) => void;

    subPath?: string;
    headers: Array<CoustomTableHeaderProps>;
    rows: Array<CoustomTableRowProps>;
    activeId: React.MutableRefObject<string | null>;
    tableMarginRight?: string;

    setexcelRecord?: (arr: T[]) => void;
    excelRecord?: T[];
    overrideExcelExport?: (Ids: string[]) => void;

    cancelButton?: () => void;
    assignButton?: (arr: string[]) => void;
    onChangeIdsRecord?: (ids: string[]) => void

    singleRowCheck?: boolean;
    rowCheck?: number | null
    setRows?: (x: Array<CoustomTableRowProps>) => void;
    direction?: React.MutableRefObject<"asc" | "desc">;
    onSortClick?: Function;

    selectedRow?: (number | null);
    setselectedRow?: React.Dispatch<React.SetStateAction<number | null>>
    disableCheck?: boolean;

    deleteFunction?: (ids: string[]) => void
    copyFunction?: (ids: string[]) => void

}

export interface CoustomTableHeaderProps {
    id: string;
    data: React.ReactNode;
    JSXelement?: JSX.Element;
    isJSXelement?: boolean;
    sortDisable?: boolean;
}



export interface CoustomTableRowProps {
    data: Array<CoustomTableHeaderProps>;
    hiddenData?: Array<CoustomTableHeaderProps>;
    Id: string;
    ischecked?: boolean;
    rowClickFunc?: () => void
    highlight_Ids?: Set<string>
}

interface withId {
    id?: string;
}



export const customTableTheme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: "Black",
                    fontFamily: "Inter, sans-serif",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    padding: '0px',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    //  maxHeight: '20px',

                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {


                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {

                root: {
                    padding: '0px',

                },
            },
        },

    },
});

/*
=> isallSelect and setallSelected
=> 
*/

const CoustomTable = <T extends withId>(CoustomTableProps: CoustomTableProps<T>) => {
    const [tempSelectedIds, setTempSelectedIds] = useState<string[]>([]);
    //const [page, setPage] = useState(0);
    //console.log(CoustomTableProps.rows.length)
    const [rowsPerPage, setRowsPerPage] = useState((CoustomTableProps.cancelButton && CoustomTableProps.assignButton && CoustomTableProps.rows.length > 20) ? CoustomTableProps.rows.length : CoustomTableProps.rowsPerPage ? CoustomTableProps.rowsPerPage : 20);
    const [isallSelect, setallSelected] = useState(false);
    //const [Rows, updateRow] = useState<Array<CoustomTableRowProps>>(CoustomTableProps.rows)
    const [isexcelButtonOn, setExcelButton] = useState(false);
    const [selectedRow, setselectedRow] = useState<null | number>(null)
    const tableRef = useRef<any>(null)
    //Rows = CoustomTableProps.rows;
    //updateRow(CoustomTableProps.rows)
    const [table, setTable] = useState<any>(null);
    useEffect(() => {
        setTable(tableRef.current)
    }, []);
    useEffect(() => {
        if (CoustomTableProps.cancelButton && CoustomTableProps.assignButton && CoustomTableProps.rows.length > 20) {
            setRowsPerPage(CoustomTableProps.rows.length);
        }
    }, [CoustomTableProps.rows]);

    // useEffect(() => {
    //     console.log(CoustomTableProps.excelRecord)
    // }, [CoustomTableProps.excelRecord])

    let sourceTypeObj: T;
    const navigate = useNavigate()

    const handleRowClick = (id: string, subPath: string) => {
        navigate(`/${subPath}/manage/${id}`);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        console.log(`handleChangePage called with newPage: ${newPage}`);
        if (CoustomTableProps.apiPage != null && CoustomTableProps.setApiPage != null) {
            CoustomTableProps.setApiPage(newPage)
            console.log("up", newPage)

        }
        else {
            CoustomTableProps.setPage(newPage);
            console.log("down")
        }
        if (table != undefined) {
            table.scrollTop = 0
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        CoustomTableProps.setPage(0);
    };

    const hoverCss = (index: number) => ({
        border: 0,
        "&:last-child td, &:last-child th": { border: 0 },
        bgcolor: index % 2 === 0 ? "white" : "rgb(200, 206, 211)",
        cursor: "default",
        "& td:first-child": {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            paddingLeft: CoustomTableProps.disableCheck ? '15px' : ''
        },
        "& td:last-child": {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            // paddingRight: 
        },
    });

    const headerCss = () => ({
        "& th": {
            color: "rgba(0, 0, 0, 0.50)",
            height: "40px",
            backgroundColor: " rgb(200, 206, 211)"

        },
        "& th:first-child": {
            borderTopLeftRadius: '8px',
            // borderBottomLeftRadius: '8px'
            paddingLeft: CoustomTableProps.disableCheck ? '15px' : '',
        },
        "& th:last-child": {
            borderTopRightRadius: '8px',
            // borderBottomRightRadius: '8px',
            paddingRight: CoustomTableProps.tableMarginRight ? `${CoustomTableProps.tableMarginRight}px` : '350px',//350px,
        },
    })

    const handleAssignButton = () => {

        //console.log("");
        //console.log(" inside coustom table..")
        //console.log(CoustomTableProps.record)

        if (CoustomTableProps.onSelectedIdsChange != null) {
            CoustomTableProps.onSelectedIdsChange(tempSelectedIds);
            if (CoustomTableProps.onChangeIdsRecord != null) {
                CoustomTableProps.onChangeIdsRecord(tempSelectedIds)
            }
        }
        if (CoustomTableProps.assignButton != null) {
            CoustomTableProps.assignButton(tempSelectedIds)
        }
    };

    const handleCancelButton = () => {
        if (CoustomTableProps.onSelectedIdsChange != null) {
            // CoustomTableProps.onSelectedIdsChange();
        }
    }


    const handleAllselected = () => {

        const updatedRows = CoustomTableProps.rows.map(row => ({
            ...row,
            ischecked: !isallSelect
        }));
        //updateRow(updatedRows);
        if (CoustomTableProps.setRows) CoustomTableProps.setRows(updatedRows)

        setallSelected(prevIsallSelect => !prevIsallSelect);
        let updatedExcelRecord: T[] = []

        if (!isallSelect) {
            setExcelButton(true);
            CoustomTableProps.rows.forEach(row => {
                let updatedSourceTypeObj: T = {
                    ...sourceTypeObj,
                    id: row.Id,
                };

                row.data?.forEach(item => {
                    updatedSourceTypeObj = { ...updatedSourceTypeObj, [item.id]: item.data };
                });

                row.hiddenData?.forEach(item => {
                    updatedSourceTypeObj = { ...updatedSourceTypeObj, [item.id]: item.data };
                });





                updatedExcelRecord = [...updatedExcelRecord, updatedSourceTypeObj]
            });


        }
        else {
            setExcelButton(false)
        }
        if (CoustomTableProps.excelRecord && CoustomTableProps.setexcelRecord) CoustomTableProps.setexcelRecord(updatedExcelRecord);
        if (CoustomTableProps.assignButton && CoustomTableProps.onSelectedIdsChange) {
            setTempSelectedIds(!isallSelect ? updatedRows.map(r => r.Id) : [])
        }
        if (CoustomTableProps.deleteFunction || CoustomTableProps.copyFunction) {
            setTempSelectedIds(!isallSelect ? updatedRows.map(r => r.Id) : [])
        }
    }



    const handleSelected = (row: CoustomTableRowProps, rowId: number) => {

        console.log("selected, ", row.Id, CoustomTableProps.rows, row, row.data?.filter(i => i.id == 'id'))
        if (CoustomTableProps.singleRowCheck != undefined && CoustomTableProps.setselectedRow != undefined) {
            CoustomTableProps.setselectedRow((prevRowCheck: (number | null)) => {
                return prevRowCheck === rowId ? null : rowId;
            })
            const selectedRow = CoustomTableProps.rows.find((r) => {
                if (r.Id == row.Id) {
                    return r;
                }
            })

            if (CoustomTableProps.assignButton && CoustomTableProps.onSelectedIdsChange) {
                if (selectedRow) {
                    setTempSelectedIds([selectedRow.Id])
                }
            }
            if (CoustomTableProps.deleteFunction || CoustomTableProps.copyFunction) {
                if (selectedRow) {
                    setTempSelectedIds([selectedRow.Id])
                }
            }


        } else {
            const updatedRows = CoustomTableProps.rows.map((r) => {
                if (r.Id === row.Id) {
                    return {
                        ...r,
                        ischecked: !r.ischecked,
                    };
                }
                return r;
            });


            let updatedSourceTypeObj: T = {
                ...sourceTypeObj,
                id: row.Id,
            };

            row.data?.forEach(item => {
                updatedSourceTypeObj = { ...updatedSourceTypeObj, [item.id]: item.data };
            });

            row.hiddenData?.forEach(item => {
                updatedSourceTypeObj = { ...updatedSourceTypeObj, [item.id]: item.data };
            });

            if (CoustomTableProps.excelRecord && CoustomTableProps.setexcelRecord) {
                // var dataId = row.data?.filter(i => i.id == 'id')[0].data;
                var record = CoustomTableProps.excelRecord.filter((item) => item.id !== row.Id)
                console.log(CoustomTableProps.excelRecord)

                console.log(record)
                CoustomTableProps.setexcelRecord(record)
            }


            if (CoustomTableProps.assignButton && CoustomTableProps.onSelectedIdsChange) { //handle info table
                setTempSelectedIds(updatedRows.filter(r => r.ischecked).map(r => r.Id))
            }

            if (CoustomTableProps.deleteFunction || CoustomTableProps.copyFunction) {
                setTempSelectedIds(updatedRows.filter(r => r.ischecked).map(r => r.Id))
            }

            if (!row.ischecked && CoustomTableProps.excelRecord && CoustomTableProps.setexcelRecord) {
                setExcelButton(true);
                CoustomTableProps.setexcelRecord([...CoustomTableProps.excelRecord, updatedSourceTypeObj])
            }
            else if (CoustomTableProps.excelRecord && CoustomTableProps.setexcelRecord) {

                if (CoustomTableProps.excelRecord.length <= 1) {

                    setExcelButton(false)
                }
            }

            if (CoustomTableProps.setRows) CoustomTableProps.setRows(updatedRows)
        }

    }

    const handlecross = () => {
        const updatedRows = CoustomTableProps.rows.map(row => ({
            ...row,
            ischecked: false
        }));
        //updateRow(updatedRows);
        if (CoustomTableProps.assignButton && CoustomTableProps.onSelectedIdsChange) setTempSelectedIds([])
        if (CoustomTableProps.deleteFunction || CoustomTableProps.copyFunction) setTempSelectedIds([])

        if (CoustomTableProps.setRows) CoustomTableProps.setRows(updatedRows)

        setallSelected(false);
        if (CoustomTableProps.excelRecord && CoustomTableProps.setexcelRecord) CoustomTableProps.setexcelRecord([]);
        setExcelButton(false);

    }


    const handleButtonclick = () => {
        if (CoustomTableProps.overrideExcelExport) {

            CoustomTableProps.overrideExcelExport(tempSelectedIds)
        }
        else {
            var wb = XLSX.utils.book_new();
            if (CoustomTableProps.excelRecord && CoustomTableProps.setexcelRecord) {
                var ws = XLSX.utils.json_to_sheet(CoustomTableProps.excelRecord);

                XLSX.utils.book_append_sheet(wb, ws, "MySheet2");
                XLSX.writeFile(wb, "MyExcel2.xlsx");
            }
        }

    }

    const handleDeleteClick = () => {
        if (CoustomTableProps.deleteFunction) {
            CoustomTableProps.deleteFunction(tempSelectedIds);
            setExcelButton(false)
        }
    }
    const handleCopyClick = () => {
        if (CoustomTableProps.copyFunction) {
            CoustomTableProps.copyFunction(tempSelectedIds);
        }
    }

    return (

        <div
            style={{
                width: CoustomTableProps.fixedWidth ? CoustomTableProps.fixedWidth : "100%",
                borderRadius: "1rem",
                zIndex: 0,
                marginTop: "10px",



            }}
        >
            <TableContainer sx={{
                maxHeight: CoustomTableProps.maxHeight ? CoustomTableProps.maxHeight : 700,
                overflow: "auto",
                borderRadius: "1rem",
                marginRight: "-20px",
                "&::-webkit-scrollbar": {
                    width: 15

                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgb(217, 217, 217)",
                    borderRadius: "1rem",

                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgb(204, 49, 27)",
                    borderRadius: 2,
                    "&:hover": {
                        backgroundColor: "rgb(204, 49, 27)", // Set the hover color to be the same as the normal color
                    }
                },
                paddingRight: "10px",//this


            }} ref={tableRef} id="tableContainer">

                <Table stickyHeader aria-label="sticky table">

                    <TableHead>
                        <TableRow
                            sx={{
                                filter: CoustomTableProps.tableBlur ? 'blur(1px)' : 'none',
                                borderTopLeftRadius: 10,
                                borderTopRightsRadius: 10,
                                // "& th": {
                                // height: "40px",
                                // backgroundColor: " rgb(200, 206, 211)"
                                // },
                                ...headerCss()

                            }}
                        >
                            {CoustomTableProps.disableCheck === undefined &&
                                <TableCell padding="checkbox"
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                        className="px-3">
                                        {(!CoustomTableProps.singleRowCheck) &&
                                            <BpCheckbox checked={isallSelect} onChange={handleAllselected} />
                                        }

                                    </div>


                                </TableCell>
                            }

                            {CoustomTableProps.headers.map((header) => (
                                <TableCell key={header.id}>
                                    <TableSortLabel
                                        active={CoustomTableProps.activeId.current === header.id}
                                        //direction={CoustomTableProps.direction.current}
                                        onClick={() => {
                                            if (CoustomTableProps.direction != undefined) {
                                                CoustomTableProps.activeId.current = header.id;
                                                if (CoustomTableProps.direction.current === "desc")
                                                    CoustomTableProps.direction.current = "asc";
                                                else if (CoustomTableProps.direction.current === "asc")
                                                    CoustomTableProps.direction.current = "desc";
                                                if (!header.sortDisable && CoustomTableProps.onSortClick != undefined) {
                                                    console.log(CoustomTableProps.direction.current)
                                                    CoustomTableProps.onSortClick();
                                                }
                                            }
                                        }}
                                    >
                                        <div className="flex items-center justify-center whitespace-nowrap">
                                            {header.data}

                                        </div>
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>

                        {
                            CoustomTableProps.rows
                                .slice(CoustomTableProps.page * rowsPerPage, CoustomTableProps.page * rowsPerPage + rowsPerPage)
                                .map((row, i) => {
                                    return <TableRow
                                        key={i + CoustomTableProps.page * rowsPerPage}
                                        sx={hoverCss(i)}
                                        style={{ height: "56px" }}
                                        className="text-white cursor-pointer"
                                        onClick={() => {
                                            if (row.rowClickFunc != undefined) {
                                                row.rowClickFunc();
                                            }
                                            else if (CoustomTableProps.subPath !== undefined) {
                                                handleRowClick(row.Id, CoustomTableProps.subPath);
                                            }
                                        }}
                                    >
                                        {CoustomTableProps.disableCheck === undefined &&
                                            <TableCell padding="checkbox" className="h-35"

                                                style={{ height: "56px" }}>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <BpCheckbox checked={!CoustomTableProps.singleRowCheck ? row.ischecked : CoustomTableProps.selectedRow == i} onChange={() => handleSelected(row, i)} onClick={(e) => e.stopPropagation()} />

                                                </div>
                                            </TableCell>}
                                        {row.data?.map((d, index) => {
                                            return (

                                                <>
                                                    {!d.isJSXelement &&
                                                        <TableCell
                                                            key={index}
                                                            sx={{
                                                                color: row.highlight_Ids?.has(d.id) ? process.env.REACT_APP_SECONDARY_COLOR_CODE : 'black'
                                                            }}

                                                        >

                                                            {d.data}
                                                        </TableCell>
                                                    }
                                                    {d.isJSXelement &&
                                                        <TableCell
                                                            key={index}
                                                            sx={{}}

                                                        >
                                                            {d.JSXelement}

                                                        </TableCell>
                                                    }

                                                </>
                                            )
                                        }
                                        )}
                                    </TableRow>
                                }
                                )

                        }
                        {[...Array(rowsPerPage - (CoustomTableProps.rows.slice(CoustomTableProps.page * rowsPerPage
                            , CoustomTableProps.page * rowsPerPage + rowsPerPage).length))].map((row, i) => (

                                <TableRow
                                    key={i + CoustomTableProps.rows.length}
                                    sx={hoverCss(i + CoustomTableProps.rows.length)}
                                    className="text-white"
                                    style={{ height: "56px" }}>

                                    {CoustomTableProps.disableCheck === undefined &&
                                        <TableCell padding="checkbox">
                                        </TableCell>}

                                    {CoustomTableProps.headers.map((data, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{}} >
                                            &nbsp;
                                        </TableCell>
                                    ))}

                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>


            {(!CoustomTableProps.assignButton || !CoustomTableProps.cancelButton) &&
                <div className="flex justify-between items-center border-t-[5px] mt-2 overflow-auto my-scrollable-div" style={{
                    borderTopColor: 'rgb(204, 49, 27)', height: "55px"
                }}>

                    <div>
                        {isexcelButtonOn && CoustomTableProps.excelRecord &&
                            <>
                                <ExportToExcelButton handleDeleteClick={CoustomTableProps.deleteFunction ? handleDeleteClick : undefined} handleCopyClick={CoustomTableProps.copyFunction ? handleCopyClick : undefined} handlecross={handlecross} items={CoustomTableProps.excelRecord.length} onClick={handleButtonclick} />

                            </>
                        }
						{!isexcelButtonOn && CoustomTableProps.remark}
                    </div>
                    <CustomTablePagination
                        rowsPerPageOptions={[]}
                        tableBlur={CoustomTableProps.tableBlur}
                        component="div"
                        count={CoustomTableProps.apiCount ? CoustomTableProps.apiCount : CoustomTableProps.rows.length}
                        rowsPerPage={rowsPerPage}
                        page={CoustomTableProps.apiPage ? CoustomTableProps.apiPage : CoustomTableProps.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className="mr-4"
                    />
                </div>
            }

            {CoustomTableProps.cancelButton && CoustomTableProps.assignButton &&
                <div className="flex justify-center items-center  mt-2" style={{
                    height: "77px"
                }}>
                    <div className="flex gap-8" >
                        <RectangleButton
                            handleClick={() => {
                                handleAssignButton();

                            }}
                            title={"Assign"}
                            backgroundColor={"#CC311B"}
                        />
                        <RectangleButton handleClick={CoustomTableProps.cancelButton} title={"Cancel"} backgroundColor={"#ACACAC"} />
                    </div>
                </div>

            }

        </div>

    );
};

export default CoustomTable;
