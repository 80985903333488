import { OptionContainer } from "../../components/FormElement/MultiLevelSelectElement/MultiLevelSelectElement";
import { SelectListOption } from "./Graph";

export interface IDataForm {
    projectId: number;
    locationId: number;
    sourceTypeId: number;
    fromDate?: string;
    toDate?: string
    isScripted?:boolean

}
export class DataFormVal implements IDataForm {
    projectId: number = -1;
    locationId: number = -1;
    sourceTypeId: number = -1;
    fromDate?: string ;
    toDate?: string ;

    constructor(init?: IDataForm) {
        Object.assign(this, init);
    }

}
export class DataError {
    projectId: boolean = false;
    locationId:boolean = false;
    sourceTypeId: boolean = false;
   
}
export class DataOptions{
    projectList:SelectListOption[] = []
    locationList:SelectListOption[] = []
    sourceTypeList:OptionContainer[] = []
}

export interface DataRow{
    dateTime:string //format will be like YYYY/MM/DD HH:MM:SS
    location?:string
    sourceType:string
    value:number
}

export interface DataTableDto{
    search_ProjectName : string
    search_LocationName : string
    search_SourceTypeName : string
    
    fromDate:string
    toDate:string
    locationId:number
    sourceTypeId:number

    datas:DataRow[]
}