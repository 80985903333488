export interface IAlarmHistoryForm {
    projectId: number;
    locationId: number;
	sourceTypeId: number;
    fromDate?: string;
    toDate?: string
}

export class AlarmHistoryFormValue implements IAlarmHistoryForm {
    
    projectId: number = -1;
    locationId: number = -1;
	sourceTypeId: number = -1;
    fromDate?: string;
    toDate?: string;
    constructor(init?: AlarmHistoryFormValue) {
        Object.assign(this, init);
    }
}