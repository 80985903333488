import { RouteObject, createBrowserRouter } from "react-router-dom";
import App from "../App";
import HomePage from "../pages/home/HomePage";
import LoginForm from "../Features/userForm/LoginForm";
import RegisterForm from "../Features/userForm/RegisterForm";
import NotFound from "../pages/error/NotFound";
import ForgetForm from "../Features/userForm/ForgetForm";
import InventoryManagement from "../pages/Inventory/InventoryManagement";

import NewForm from "../components/form/SourceTypeCreateForm";
import SourceTypeRecord from "../pages/SourceType/SourceTypeRecord";


import Test from "../pages/test";
import StatusSwitch from "../components/Button/StatusSwitch";
import InventoryCreate from "../pages/Inventory/InventoryCreatePage";
import Login from "../pages/login/login";
import { ProjectManagementMenu } from "../pages/Menu/ProjectManagementMenu";
import LoggerSetCreatePage from "../pages/LoggerSetMaintenance/LoggerSetCreatePage";
import SourceTypeCreatePage from "../pages/SourceType/SourceTypeCreatePage";
import { MenuTemplate } from "../pages/Menu/MenuTemplate";
import { LoggerInfoTable } from "../components/form/LoggerSet/LoggerInfo/LoggerInfoTable";
import ProjectCreatePage from "../pages/Project/ProjectCreatePage";
import ProjectRecord from "../pages/Project/ProjectRecord";
import LoggerSetMaintenance from "../pages/LoggerSetMaintenance/LoggerSetMaintenance";
import LocationManagement from "../pages/Location/LocationManagement";
import LocationCreatePage from "../pages/Location/LocationCreatePage";
import CustomerRecord from "../pages/Customer/CustomerRecord";
import CustomerViewPage from "../pages/Customer/CustomerViewPage";
import AccountRecord from "../pages/Account/AccountRecord";
import AccountEditPage from "../pages/Account/Edit/AccountEditPage";
import AccountCreatePage from "../pages/Account/AccountCreatePage";
import GraphPage from "../pages/Graph/GraphPage";
import InventoryImportPage from "../pages/Inventory/InventoryImportPage";
import Graph from "../pages/Graph/GraphForm";
import DataPage from "../pages/Data/DataPage";
import PerformancePage from "../pages/PerformanceSummary/PerformancePage";
import ChangePwPage from "../pages/ChangePassword/ChangePwPage";
import AlarmPage from "../pages/Alarm/AlarmPage";
import ResetForm from "../Features/userForm/ResetForm";
import DashboardPage from "../pages/Dashboard/DashboardPage";


export const routes: RouteObject[] = [
    {
        path: '/', // root, first page
        element: <App />,
        children: [ //children will extend the '/'
            //{ path: 'home', element: <HomePage /> },
            { path: 'graph', element: <GraphPage /> },
            { path: 'graph/query/:projectId', element: <GraphPage key="query"/> },

            { path: 'alarm', element: <AlarmPage /> },
                
            { path: 'data', element: <DataPage /> },
            { path: 'performance', element: <PerformancePage /> },
            { path: 'changePassword', element: <ChangePwPage /> },


            { path: 'not-found', element: <NotFound /> },

            //{ path: 'inventory', element: <InventoryManagement /> },
            //{ path: 'projectForm', element: <NewForm /> },
            // { path: 'sourceType', element: <SourceTypeRecord /> },
            // { path: 'projectForm', element: <ProjectForm /> },

            //{ path: 'InventoryCreate', element: <InventoryCreate /> },
            { path: 'projectManagementMenu', element: <ProjectManagementMenu /> },
            { path: 'createLoggerset', element: <LoggerSetCreatePage /> },
            { path: 'LoggersetMaintenance', element: <LoggerSetMaintenance /> },
            { path: 'LoggersetMaintenance/manage/:id', element: <LoggerSetCreatePage key='manage2' /> },

            { path: 'account', element: <AccountRecord /> },
            { path: 'account/manage/:id', element: <AccountEditPage /> },
            { path: 'account/create', element: <AccountCreatePage /> },


            { path: 'project', element: <ProjectRecord /> },
            { path: 'project/create', element: <ProjectCreatePage /> },
            { path: 'project/manage/:id', element: <ProjectCreatePage key='manage' /> },


            { path: 'inventory', element: <InventoryManagement /> },
            { path: 'inventory/create', element: <InventoryCreate /> },
            { path: 'inventory/import', element: <InventoryImportPage /> },

            { path: 'Inventory/manage/:id', element: <InventoryCreate key='manage' /> },

            { path: "sourceType", element: <SourceTypeRecord /> },
            { path: 'sourceType/manage/:id', element: <SourceTypeCreatePage key='manage2' /> },
            { path: 'sourceType/create', element: <SourceTypeCreatePage /> },
            // {
            //     path: 'sourceType',
            //     element: <SourceTypeRecord />,
            //     children: [
            //         { path: 'manage/:id', element: <SourceTypeCreatePage key='manage2' /> },

            //     ]
            // },
            { path: 'customer', element: <CustomerRecord /> },
            { path: 'customer/manage/:id', element: <CustomerViewPage /> },


            //{ path: 'mainMenu', element: <MenuTemplate /> },
            {
                path: 'loginPage',
                element: <Login />,
                children: [
                    { path: 'login', element: <LoginForm /> },
                    { path: 'register', element: <RegisterForm /> },
                    { path: 'forget-password', element: <ForgetForm /> },
                    { path: 'reset-password', element: <ResetForm /> },

                ]
            },
            { path: "location", element: <LocationManagement /> },
            { path: "location/create", element: <LocationCreatePage /> },
            { path: "location/manage/:id", element: <LocationCreatePage key="manage" /> },
           // { path: 'testalarm', element: <AlarmTest /> },
          //  {path: 'overview', element: <OverviewRecord/>},
            {path: 'dashboard', element: <DashboardPage/>},
           // {path: 'overview2', element: <OverviewRecord2/>}

           { path: '*', element: <NotFound /> },
            
        ]
    }


]

export const router = createBrowserRouter(routes)
