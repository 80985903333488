import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react'
import CoustomTable, { CoustomTableHeaderProps, CoustomTableRowProps, customTableTheme } from '../../components/Table/CoustomTable';
import { useStore } from '../../stores/store';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import LoadingComponent from '../../components/LoadingComponent';
import { states } from '../SourceType/SourceTypeRecord';
import DataTableHeader from '../Data/DataTableHeader';
import { sortingAlgor } from '../../hook/useSorting';
import ScriptRemark from '../../components/Other/ScriptRemark';
import agent from '../../services/Api/agent';
import { AlarmDto } from '../../models/Alarm';

export default observer(function AlarmTable() {

    const [page, setPage] = useState(0);
    const [dataTimeState, setdateTimeState] = useState("");
    const [stationNameState, setstationNameState] = useState("");
    const [valueState, setvalueState] = useState("");

    const [buttonChoice, setButtonChoice] = useState("dateTime");

    const [excelRecord, setexcelRecord] = useState<AlarmDto[]>([]);
    const [rows, setrows] = useState<CoustomTableRowProps[]>([]);
    const [Results, setResult] = useState<AlarmDto[]>([])

    const { alarmStore } = useStore();

    const { AlarmHistories, loadingInitial } = alarmStore
    const navigate = useNavigate();
    
	const { userStore, commonStore } = useStore();
	const { user, CheckIfHkiaEmail } = userStore;

    const statesArray: states[] = [
        {
            currentstate: dataTimeState,
            statemethod: setdateTimeState
        },
        {
            currentstate: stationNameState,
            statemethod: setstationNameState
        },
        {
            currentstate: valueState,
            statemethod: setvalueState
        },

    ]

    useEffect(() => {
        setPage(0)
    }, [buttonChoice, AlarmHistories])

    useEffect(() => {
        const initialRows: Array<CoustomTableRowProps> =
            Results.filter((item) => {
                return dataTimeState.toLowerCase() == "" ? item
                    : item.dateTime?.toLowerCase().includes(dataTimeState.toLowerCase());
            }).filter((item) => {
                return stationNameState.toLowerCase() == "" ? item
                    : item.location?.toLowerCase().includes(stationNameState.toLowerCase());
            }).filter((item) => {
                return valueState.toLowerCase() == "" ? item
                    : item.level?.toString().toLowerCase().includes(valueState.toLowerCase());
            }).map(
                (record: AlarmDto) => ({

                    Id: record.id.toString(),
                    ischecked: false,

                    data: [
                        { id: "dateTime", data: record.dateTime, isJSXelement: false },
                        { id: "stationName", data: record.location },
                        { id: "sourceType", data: record.sourceType ? record.sourceType : "------", isJSXelement: false },
                        { id: "level", data: record.level },

                    ]
                })
            )
        setrows(initialRows)
        console.log(initialRows)
    }, [Results, dataTimeState, stationNameState, valueState])
    useEffect(() => {
        if (AlarmHistories !== undefined) {
            const Results2 = AlarmHistories
            setResult(Results2)
        }

    }, [AlarmHistories])

    const headers: Array<CoustomTableHeaderProps> = [
        { id: "dateTime", data: "DateTime" },
        { id: "stationName", data: "Station Name" },
        { id: "sourceType", data: "SourceType" },
        { id: "status", data: "Status" },

    ];





    const activeId = useRef<string | null>(null)
    const direction = useRef<'asc' | 'desc'>('desc');
    const onSortClick = () => {
        setPage(0);
        sortingAlgor({ rows, setrows, direction, activeId });
    };

    const stateSelection = buttonChoice === "dateTime"
        ? setdateTimeState
        : buttonChoice === "stationName"
            ? setstationNameState
            : setvalueState

    if (loadingInitial)
        return <LoadingComponent content="Loading activity..." />;
    if (AlarmHistories === undefined)
        return <div className="h-[600px] flex items-center justify-center"><span className="text-[#CC311B]">No record</span></div>

    const deleteFunction = (itemIds: string[]) => {
        var dataList: {}[] = []
        excelRecord.map((record)=>{
            if(record !== undefined){
                var data = {DateTime: new Date(record.dateTime?.replaceAll("/","-").replace(" ","T") + "Z"),
                    LocationName: record.location,
                    SourceType: record.sourceType.substring(0, record.sourceType.length-1),
                    Level: record.level
                }
                dataList.push(data)
            }
        })
        agent.Data.deleteData(dataList).then((response)=>{
            alert("Deletion Complete")
            setexcelRecord([])
            itemIds.map((id)=>{
                var index = rows.findIndex(x=> x.Id === id)
                rows.splice(index, 1)
            })
            itemIds = []
        })
    }


    return (

        <div className=" pageParentDiv" >
            <DataTableHeader buttonChoice={buttonChoice} statesArray={statesArray.filter((item) => (item.currentstate !== buttonChoice))} setState={stateSelection} setButtonChoice={setButtonChoice} options={headers.filter((item) => item.id == "dateTime" || item.id == "stationName" || item.id == "sourceType" || item.id == "value")} title={"owo"} />
            <ThemeProvider theme={customTableTheme}>
                <CoustomTable deleteFunction={user?.role==="Project70Admin"? deleteFunction : undefined} onSortClick={onSortClick} direction={direction} page={page} setPage={setPage} activeId={activeId} excelRecord={excelRecord} setexcelRecord={setexcelRecord} headers={headers} rows={rows} setRows={setrows} />
            </ThemeProvider>
        </div>


    )
})
